import {createAction} from '@reduxjs/toolkit'
import {OrderStatus} from '@wix/events-types'
import {
  CouponErrorCode,
  GiftCardErrorCode,
  getEventId,
  getOrderGiftCardsTotalAmount,
  getValidGiftCardDetails,
  isAssignedTickets,
} from '@wix/wix-events-commons-statics'
import {isMobile, isPreview, isSite} from '../../../../commons/selectors/environment'
import {isTemplate} from '../../../../commons/selectors/instance'
import {CHECKOUT_STEPS, FormStep, UserRole} from '../constants'
import {
  getBuyerDetails,
  getCurrentStep,
  getInvalidTicketIndex,
  getTicketsDetails,
  isAllTicketDetailsValid,
  isStepCompleted,
  isStepVisible,
  isValidPaymentAdded,
} from '../selectors/checkout'
import {isUpgradeNeededForCheckout} from '../selectors/checkout-options'
import {getEvent} from '../selectors/event'
import {
  couponSubmitted,
  getCouponCode,
  getGiftCardCode,
  getGiftCardPaymentDetails,
  giftCardSubmitted,
} from '../selectors/invoice'
import {isEventPreview} from '../selectors/navigation'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {getInvoice, isOrderCompleted} from '../selectors/placed-order'
import {hasAgreedWithPolicies, hasPolicies} from '../selectors/policies'
import {getReservationId} from '../selectors/reservation'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState, RegFormData} from '../types'
import {isReservationIdFromQuery} from '../utils/navigation'
import {couponApplied} from './coupon'
import {giftCardApplied} from './gift-card'
import {ensureLoginForMembersOnly} from './members'
import {openCantCompletePaymentModal, openCheckoutUnavailable, openUpgradeToPremium} from './modals'
import {navigateToTemplateOrder} from './navigation'
import {placeOrder, postPlaceOrder, updateOrder} from './placed-order'
import {reserveTickets} from './reservation'

export const NEXT_FORM_CLICKED = 'NEXT_FORM_CLICKED'
export const SET_BUYER_DETAILS = 'SET_BUYER_DETAILS'
export const SET_TICKET_DETAILS = 'SET_TICKET_DETAILS'
export const NEXT_STEP = 'NEXT_STEP'
export const EDIT_STEP = 'EDIT_STEP'
export const SET_VALID_PAYMENT_ADDED = 'SET_VALID_PAYMENT_ADDED'
export const USE_BUYER_DETAILS = 'USE_BUYER_DETAILS'
export const SET_EXPANDED_TICKET_INDEX = 'SET_EXPANDED_TICKET_INDEX'
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT'

export const nextFormClicked = () => ({type: NEXT_FORM_CLICKED})

export const setExpandedTicketIndex = (index: number) => ({type: SET_EXPANDED_TICKET_INDEX, payload: {index}})

export const setValidPaymentAdded = (validPaymentAdded: boolean) => ({
  type: SET_VALID_PAYMENT_ADDED,
  payload: {validPaymentAdded},
})

interface GetDiscountParams {
  eventId: string
  reservationId: string
}

interface GetDiscountResponse {
  invoice: wix.events.ticketing.Invoice
  discountErrors: wix.events.ticketing.DiscountErrors
  giftCardErrors: wix.events.ticketing.GiftCardErrors
  giftCardPaymentDetails: wix.events.ticketing.GiftCardPaymentDetails[]
}

export const getDiscount = createAsyncAction<GetDiscountResponse, GetDiscountParams>(
  'GET_DISCOUNT',
  async ({eventId, reservationId}, {getState, dispatch, extra: {serverApi}}) => {
    const state = getState()
    const couponCode = getCouponCode(state)
    const giftCardCode = getGiftCardCode(state)
    const {benefitId, planOrderId} = getSelectedPaidPlan(state) ?? {}

    if (!reservationId) {
      return {invoice: null, discountErrors: null, giftCardErrors: null, giftCardPaymentDetails: []}
    }

    if (couponSubmitted(state) && !couponCode) {
      return {
        invoice: null,
        discountErrors: {error: [{code: CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST}]},
        giftCardErrors: null,
        giftCardPaymentDetails: [],
      }
    }

    if (giftCardSubmitted(state) && !giftCardCode) {
      return {
        invoice: null,
        discountErrors: null,
        giftCardErrors: {error: [{code: GiftCardErrorCode.EVENTS_GIFT_CARD_NOT_FOUND}]},
        giftCardPaymentDetails: [],
      }
    }

    const {invoice, discountErrors, giftCardErrors, giftCardPaymentDetails} = await serverApi.getInvoice(
      eventId,
      reservationId,
      couponCode,
      giftCardCode,
      benefitId,
      planOrderId,
    )
    const validGiftCardPaymentDetails = getValidGiftCardDetails(giftCardPaymentDetails)

    if (invoice?.discount) {
      dispatch(couponApplied())
    }

    if (validGiftCardPaymentDetails.length) {
      dispatch(giftCardApplied())
    }

    return {invoice, discountErrors, giftCardErrors, giftCardPaymentDetails: validGiftCardPaymentDetails}
  },
)

export const checkout = createAsyncAction('CHECKOUT', async (_, {getState, dispatch}) => {
  const state = getState()
  const event = getEvent(state)

  if (await dispatch(ensureLoginForMembersOnly())) {
    return dispatch(reserveTickets(getEventId(event)))
  }
})

export const placeOrderCheckout = createAsyncAction<void, {buyer: RegFormData; guests?: RegFormData[]}>(
  'PLACE_ORDER_CHECKOUT',
  async ({buyer, guests}, {getState, dispatch, extra: {wixCodeApi}}) => {
    const state = getState()
    const owner = wixCodeApi.user.currentUser.role === UserRole.ADMIN
    const event = getEvent(state)
    const eventId = getEventId(event)
    const placedOrder = state.placedOrder.order
    const upgradeNeeded = isUpgradeNeededForCheckout(state)

    if (isPreview(state)) {
      dispatch(openCantCompletePaymentModal())
    }

    if (!(await dispatch(ensureLoginForMembersOnly()))) {
      return
    }

    if (upgradeNeeded) {
      if (isMobile(state) || !owner) {
        return dispatch(openCheckoutUnavailable())
      } else {
        if (isReservationIdFromQuery(wixCodeApi)) {
          const {proceed} = await dispatch(openUpgradeToPremium()).unwrap()
          if (!proceed) {
            return
          }
        }
      }
    }

    if (isSite(state)) {
      const normalizedBuyer = normalizeFormData(buyer)
      const normalizedGuests = guests ? guests.map(normalizeFormData) : undefined

      if (isOrderCompleted(placedOrder)) {
        const updateOrderResponse = await dispatch(updateOrder({buyer: normalizedBuyer, guests: normalizedGuests}))
        const order = getState().placedOrder.order

        if (
          order.status === OrderStatus.FREE ||
          getOrderGiftCardsTotalAmount(order.giftCardPaymentDetails) === Number(order.invoice.grandTotal.amount)
        ) {
          dispatch(postPlaceOrder(order))
        }

        return updateOrderResponse
      } else {
        return dispatch(placeOrder(eventId, normalizedBuyer, normalizedGuests))
      }
    }
  },
)

export const setBuyerDetails = createAction<RegFormData>('SET_BUYER_DETAILS')

export const submitCheckoutStep = (data: any) => async (dispatch: Function, getState: GetState) => {
  const state = getState()
  const currentStep = getCurrentStep(state)
  const assignedTicketsEnabled = isAssignedTickets(getEvent(state))
  const placedOrder = state.placedOrder.order
  const buyerDetails = getBuyerDetails(state)
  const ticketsDetails = getTicketsDetails(state)
  const reservationId = getReservationId(state)
  const policies = hasPolicies(state)
  const agreedWithPolicies = hasAgreedWithPolicies(state)
  const template = isTemplate(state)
  const allTicketDetailsValid = isAllTicketDetailsValid(state)

  switch (currentStep) {
    case FormStep.BuyerDetails: {
      dispatch(setBuyerDetails(data))

      if (template || isEventPreview(state)) {
        dispatch(navigateToTemplateOrder())
        break
      }

      if (!allTicketDetailsValid) {
        const invalidTicketIndex = getInvalidTicketIndex(state)
        await dispatch(setExpandedTicketIndex(invalidTicketIndex))
      }

      if (policies && !agreedWithPolicies) {
        break
      }

      if (!assignedTicketsEnabled || isOrderCompleted(placedOrder)) {
        await dispatch(
          placeOrderCheckout({buyer: data, guests: assignedTicketsEnabled ? ticketsDetails : null}),
        ).unwrap()
      }
      break
    }
    case FormStep.TicketsDetails: {
      if (agreedWithPolicies || !policies) {
        await dispatch(
          placeOrderCheckout({buyer: {...buyerDetails, reservation: reservationId}, guests: data}),
        ).unwrap()
      }
      break
    }
    case FormStep.Policies: {
      await dispatch(
        placeOrderCheckout({buyer: buyerDetails, guests: assignedTicketsEnabled ? ticketsDetails : null}),
      ).unwrap()
      break
    }
    default:
      break
  }

  dispatch(handleNextStep())
}

export const handleNextStep = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const assignedTicketsEnabled = isAssignedTickets(getEvent(state))

  if (
    isStepCompleted({
      step: getCurrentStep(state),
      validPaymentAdded: isValidPaymentAdded(state),
      buyerDetails: getBuyerDetails(state),
      placedOrder: state.placedOrder.order,
      assignedTicketsEnabled,
      agreedWithPolicies: hasAgreedWithPolicies(state),
      hasPolicies: hasPolicies(state),
      allTicketDetailsValid: isAllTicketDetailsValid(state),
    })
  ) {
    dispatch(nextStep())
  }
}

export const editStep = (currentStep: FormStep, validPaymentAdded: boolean) => ({
  type: EDIT_STEP,
  payload: {
    currentStep,
    validPaymentAdded,
  },
})

const nextStep = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const {validPaymentAdded, buyerDetails} = state.checkout
  const assignedTicketsEnabled = isAssignedTickets(getEvent(state))

  dispatch({
    type: NEXT_STEP,
    payload: {
      nextStep: CHECKOUT_STEPS.find(step => {
        const isVisible = isStepVisible({
          step,
          assignedTicketsEnabled,
          invoice: getInvoice(state),
          giftCardPaymentDetails: getGiftCardPaymentDetails(state),
          hasPolicies: hasPolicies(state),
          isTemplate: isTemplate(state),
          eventPreview: isEventPreview(state),
        })
        const isCompleted = isStepCompleted({
          step,
          validPaymentAdded,
          buyerDetails,
          placedOrder: state.placedOrder.order,
          assignedTicketsEnabled,
          agreedWithPolicies: hasAgreedWithPolicies(state),
          hasPolicies: hasPolicies(state),
          allTicketDetailsValid: isAllTicketDetailsValid(state),
        })

        return isVisible && !isCompleted
      }),
    },
  })
}

export const clearCheckout = () => ({
  type: CLEAR_CHECKOUT,
})

const normalizeFormData = (formData = {} as RegFormData) => {
  const normalizedFormData = {
    ...formData,
  }

  if (formData.email) {
    normalizedFormData.email = formData.email.trim()
  }

  if (formData.additionalGuests) {
    normalizedFormData.guestNames = formData.additionalGuests.guestNames.map(
      ({firstName, lastName}: any) => `${firstName} ${lastName}`,
    )
  }

  return normalizedFormData
}

export const setUseBuyerDetails = createAction<{ticketIndex?: number; useBuyerDetails: boolean}>('USE_BUYER_DETAILS')

export const setTicketDetails = (index: number, details: RegFormData, valid: boolean) => ({
  type: SET_TICKET_DETAILS,
  payload: {index, details, valid},
})
