import {isTicketed} from '@wix/wix-events-commons-statics'
import {State} from '../types'
import {getEvent} from './event'
import {getInvoice} from './placed-order'
import {getSortedSelectedTickets} from './selected-tickets'
import {getTickets, isFixedPriceTicketSelected} from './tickets'

export const isCouponsAccepted = (state: State) => state.checkoutOptions.options.acceptCoupons

export const isGiftCardsAccepted = (state: State) => state.checkoutOptions.options.acceptGiftCards

export const isUpgradeNeededForCheckout = (state: State) => {
  const {
    grandTotal: {amount},
  } = getInvoice(state)

  return !state.checkoutOptions.options.premiumServices && Number(amount) > 0
}

export const isCouponInputVisible = (state: State) => {
  const tickets = getTickets(state)
  const sortedSelectedTickets = getSortedSelectedTickets(state)
  const event = getEvent(state)

  return isFixedPriceTicketSelected(tickets, sortedSelectedTickets) && isTicketed(event) && isCouponsAccepted(state)
}
