import {
  CouponErrorCode,
  GiftCardErrorCode,
  getValidGiftCardDetails,
  hasCouponDiscount,
  hasPaidPlanDiscount,
} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {getDiscount} from '../actions/checkout'
import {RESET_COUPON_CODE, SUBMIT_COUPON} from '../actions/coupon'
import {resetGiftCardCode, submitGiftCard} from '../actions/gift-card'
import {CLEAR_INVOICE} from '../actions/invoice'
import {PLACE_ORDER, UPDATE_ORDER, getOrder} from '../actions/placed-order'
import {InvoiceState} from '../types'

export const getDefaultInvoiceState = (): InvoiceState => ({
  couponError: null,
  giftCardError: null,
  invoice: null,
  giftCardPaymentDetails: undefined,
  coupon: {
    code: undefined,
    submitted: false,
    ticketId: null,
    validating: false,
  },
  giftCard: {
    code: undefined,
    submitted: false,
    validating: false,
  },
})

export const invoice = (state = getDefaultInvoiceState(), action: AnyAction): InvoiceState => {
  switch (action.type) {
    case getDiscount.fulfilled.toString():
    case PLACE_ORDER.SUCCESS:
    case UPDATE_ORDER.SUCCESS:
    case getOrder.fulfilled.type:
      return getDiscountSuccess(state, action)
    case getDiscount.rejected.toString(): {
      const submittingCoupon = state.coupon.submitted && state.coupon.validating
      const submittingGiftCard = state.giftCard.submitted && state.giftCard.validating

      return {
        ...state,
        couponError: submittingCoupon ? CouponErrorCode.CUSTOM_ERROR_TOO_BUSY : state.couponError,
        giftCardError: submittingGiftCard ? GiftCardErrorCode.CUSTOM_ERROR_UNKNOWN : state.giftCardError,
        coupon: submittingCoupon
          ? {
              ...state.coupon,
              submitted: false,
              ticketId: null,
              validating: false,
            }
          : state.coupon,
        giftCard: submittingGiftCard
          ? {
              ...state.giftCard,
              submitted: false,
              validating: false,
            }
          : state.giftCard,
      }
    }
    case SUBMIT_COUPON: {
      const defaultState = getDefaultInvoiceState()

      return {
        ...state,
        coupon: {
          ...state.coupon,
          code: action.payload,
          submitted: true,
          validating: true,
        },
        giftCard: state.giftCard.submitted ? state.giftCard : defaultState.giftCard,
        giftCardError: state.giftCard.submitted ? state.giftCardError : defaultState.giftCardError,
      }
    }
    case submitGiftCard.type: {
      const defaultState = getDefaultInvoiceState()

      return {
        ...state,
        giftCard: {
          code: action.payload,
          submitted: true,
          validating: true,
        },
        coupon: state.coupon.submitted ? state.coupon : defaultState.coupon,
        couponError: state.coupon.submitted ? state.couponError : defaultState.couponError,
      }
    }
    case RESET_COUPON_CODE:
      return {
        ...getDefaultInvoiceState(),
        giftCard: state.giftCard,
        giftCardError: state.giftCardError,
        giftCardPaymentDetails: state.giftCardPaymentDetails,
      }
    case resetGiftCardCode.type:
      return {
        ...getDefaultInvoiceState(),
        coupon: state.coupon,
        couponError: state.couponError,
      }
    case CLEAR_INVOICE:
      return getDefaultInvoiceState()
    default:
      return state
  }
}

const getDiscountSuccess = (state: InvoiceState, action: AnyAction) => {
  if (getDiscount.fulfilled.match(action)) {
    const {invoice: returnedInvoice, discountErrors, giftCardErrors, giftCardPaymentDetails} = action.payload
    const couponError = getCouponErrorType(discountErrors)
    const giftCardError = getGiftCardErrorType(giftCardErrors)

    if (couponError || giftCardError) {
      return {
        ...state,
        couponError,
        giftCardError,
        coupon: couponError
          ? {
              ...state.coupon,
              submitted: false,
              ticketId: null,
              validating: false,
            }
          : state.coupon,
        giftCard: giftCardError
          ? {
              ...state.giftCard,
              submitted: false,
              validating: false,
            }
          : state.giftCard,
      }
    }

    const withCouponDiscount = hasCouponDiscount(returnedInvoice)
    const withPaidPlanDiscount = hasPaidPlanDiscount(returnedInvoice)
    const withGiftCard = !!giftCardPaymentDetails.length

    if (withCouponDiscount || withPaidPlanDiscount || withGiftCard) {
      const coupon = withCouponDiscount
        ? {
            ...state.coupon,
            submitted: true,
            ticketId: gatherCouponTicketId(returnedInvoice.items),
            validating: false,
          }
        : state.coupon

      const giftCard = withGiftCard
        ? {
            ...state.giftCard,
            submitted: true,
            validating: false,
          }
        : state.giftCard

      return {
        ...state,
        couponError,
        giftCardError,
        invoice: returnedInvoice,
        coupon,
        giftCard,
        giftCardPaymentDetails,
      }
    }

    if (state.coupon.submitted || state.giftCard.submitted) {
      return {
        ...state,
        couponError: state.coupon.submitted ? CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST : state.couponError,
        giftCardError: state.giftCard.submitted ? GiftCardErrorCode.EVENTS_GIFT_CARD_NOT_FOUND : state.giftCardError,
        invoice: returnedInvoice,
        coupon: state.coupon.submitted
          ? {
              ...state.coupon,
              submitted: false,
              ticketId: null,
              validating: false,
            }
          : state.coupon,
        giftCard: state.giftCard.submitted
          ? {
              ...state.giftCard,
              submitted: false,
              validating: false,
            }
          : state.giftCard,
      }
    }

    return {
      ...state,
      couponError,
      giftCardError,
      invoice: returnedInvoice,
    }
  } else {
    return {
      ...state,
      invoice: action.payload.order.invoice,
      giftCardPaymentDetails: getValidGiftCardDetails(action.payload.order.giftCardPaymentDetails),
    }
  }
}

const getGiftCardErrorType = (giftCardErrors: wix.events.ticketing.GiftCardErrors | null) => {
  if (!giftCardErrors?.error?.length) {
    return null
  }

  switch (giftCardErrors.error[0].code) {
    case GiftCardErrorCode.EVENTS_GIFT_CARD_NOT_FOUND:
    case GiftCardErrorCode.EVENTS_GIFT_CARD_INSUFFICIENT_FUNDS:
    case GiftCardErrorCode.EVENTS_GIFT_CARD_DISABLED:
    case GiftCardErrorCode.EVENTS_GIFT_CARD_EXPIRED:
      return giftCardErrors.error[0].code
    default:
      return GiftCardErrorCode.CUSTOM_ERROR_UNKNOWN
  }
}

const getCouponErrorType = (discountErrors: wix.events.ticketing.DiscountErrors | null) => {
  const hasError = discountErrors && discountErrors.error && discountErrors.error.length

  if (!hasError) {
    return null
  }

  const errorCode = discountErrors.error[0].code

  switch (errorCode) {
    case CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST:
    case CouponErrorCode.ERROR_COUPON_HAS_EXPIRED:
    case CouponErrorCode.ERROR_COUPON_IS_NOT_ACTIVE_YET:
    case CouponErrorCode.ERROR_COUPON_USAGE_EXCEEDED:
    case CouponErrorCode.CUSTOM_ERROR_TOO_BUSY:
      return errorCode
    case CouponErrorCode.ERROR_COUPON_IS_DISABLED:
      return CouponErrorCode.ERROR_COUPON_DOES_NOT_EXIST
    default:
      return CouponErrorCode.CUSTOM_ERROR_UNKNOWN
  }
}

const gatherCouponTicketId = (items: wix.events.ticketing.Item[]) => {
  const itemsWithDiscount = items.filter(item => item.discount)

  if (items.length !== itemsWithDiscount.length && itemsWithDiscount.length > 0) {
    return itemsWithDiscount[0].id
  }

  return null
}
