import type {ClientStatusPublic} from '@wix/cashier-common/dist/src/types/PaymentStatus'
import {poll, waitALittle} from '@wix/panda-js-utils'
import {
  EVENTS_APP_ID,
  EVENTS_DETAILS_PAGE_ID,
  PlanInfo,
  getCouponDiscountCode,
  getOrderGiftCardsTotalAmount,
} from '@wix/wix-events-commons-statics'
import {ILocation} from '@wix/yoshi-flow-editor'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {getLocale} from '../../../../commons/selectors/environment'
import {isMembersInstalled} from '../../../../commons/selectors/installed-apps'
import {getCurrentMemberDetails} from '../selectors/current-member-details'
import {getEvent} from '../selectors/event'
import {getCouponCode, getGiftCardCode} from '../selectors/invoice'
import {
  getNavigationQuery,
  getPaymentRedirectUrl,
  isOrderPending as isOrderPendingRoute,
  isOrderSuccess as isOrderSuccessRoute,
} from '../selectors/navigation'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {isRequestPending} from '../selectors/pending-requests'
import {getInvoice, isPreparingOrder, onlyFreeTicketsSelected} from '../selectors/placed-order'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState, RegFormData, StoreExtraArgs} from '../types'
import {extractFormData} from '../utils/api-data-mapper'
import {Interaction} from '../utils/interactions'
import {navigateTo, navigateToLinkExpired, navigateToNotFound} from './navigation'
import {placeOrderButtonClicked} from './payment'

export const PLACE_ORDER = createActions('PLACE_ORDER')
export const UPDATE_ORDER = createActions('UPDATE_ORDER')

export const placeOrder =
  (eventId: string, buyer: RegFormData, guests?: RegFormData[]) =>
  (dispatch: Function, getState: GetState, {flowAPI, wixCodeApi}: StoreExtraArgs) => {
    const state = getState()

    if (isRequestPending(state, PLACE_ORDER.REQUEST)) {
      return
    }

    flowAPI.fedops.interactionStarted(Interaction.Checkout)

    const couponCode = getCouponDiscountCode(getInvoice(state))
    const giftCardCode = getGiftCardCode(state)
    const memberData = getCurrentMemberDetails(state)
    const memberId = isMembersInstalled(state) && memberData ? memberData.id : undefined
    const selectedPlan = getSelectedPaidPlan(state) || ({} as PlanInfo)
    const {benefitId, planOrderId} = selectedPlan
    const paymentRedirectUrl = getPaymentRedirectUrl(state, wixCodeApi)

    const orderGuests = guests ? guests.map(extractFormData) : null

    return dispatch(
      callAPI(PLACE_ORDER, {
        eventId,
        buyer,
        guests: orderGuests,
        couponCode,
        giftCardCode,
        memberId,
        planOrderId,
        benefitId,
        locale: getLocale(state),
        paymentRedirectUrl,
      }),
    ).then((response: any) => {
      const order = response.order

      if (onlyFreeTicketsSelected(order)) {
        dispatch(placeOrderButtonClicked())
      }

      dispatch(postPlaceOrder(order))

      return response
    })
  }

export const postPlaceOrder =
  (order: wix.events.ticketing.Order) =>
  (dispatch: Function, getState: GetState, {flowAPI}: StoreExtraArgs) => {
    flowAPI.fedops.interactionEnded(Interaction.Checkout)
    const totalPrice = Number(order.totalPrice.amount) - getOrderGiftCardsTotalAmount(order.giftCardPaymentDetails)

    if (!totalPrice) {
      dispatch(navigateTo(getState().placedOrder.orderPageUrl))
    }
  }

interface UpdateOrderParams {
  buyer?: RegFormData
  guests?: RegFormData[]
  paymentDetailsId?: string
  paymentMethod?: string
}

export const updatePaymentMethod =
  ({paymentMethod}: {paymentMethod: string}) =>
  (dispatch: Function, getState: GetState) => {
    const state = getState()
    const buyer = state.checkout.buyerDetails
    const guests = state.checkout.ticketsDetails

    return dispatch(updateOrder({buyer, guests, paymentMethod}))
  }

export const updateOrder =
  ({buyer, guests, paymentDetailsId, paymentMethod}: UpdateOrderParams) =>
  (dispatch: Function, getState: GetState) => {
    const state = getState()
    const eventId = getEvent(state).id
    const selectedPlan = getSelectedPaidPlan(state) || ({} as PlanInfo)
    const {benefitId, planOrderId} = selectedPlan

    return dispatch(
      callAPI(UPDATE_ORDER, {
        eventId,
        paymentDetailsId,
        paymentMethod,
        orderNumber: state.placedOrder.order.orderNumber,
        buyer: extractFormData(buyer),
        guests: guests?.map(extractFormData),
        locale: getLocale(state),
        couponCode: getCouponCode(state),
        giftCardCode: getGiftCardCode(state),
        paidPlanBenefit: benefitId && planOrderId ? {benefitId, planOrderId} : undefined,
      }),
    )
  }

export const expressCheckout = createAsyncAction<{clientStatus: ClientStatusPublic}, {paymentDetailsId: string}>(
  'EXPRESS_CHECKOUT',
  async (params, {dispatch, extra: {flowAPI}, getState}) => {
    const state = getState()
    const [{confirmPayment}, updateOrderResponse] = await Promise.all([
      import('@wix/cashier-express-checkout-widget/dist/src/utils/confirmPayment'),
      dispatch(
        updateOrder({
          buyer: state.checkout.buyerDetails,
          guests: state.checkout.ticketsDetails,
          paymentDetailsId: params.paymentDetailsId,
        }),
      ),
    ])
    const {chargeToken, orderPageUrls} = updateOrderResponse

    const confirmPaymentResponse = await confirmPayment({
      meta: {
        appDefId: EVENTS_APP_ID,
        msid: flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId!,
        visitorId: flowAPI.controllerConfig.platformAPIs?.bi?.visitorId!,
        widgetId: EVENTS_DETAILS_PAGE_ID,
      },
      biLoggerFactory: flowAPI.essentials.biLoggerFactory,
      chargeResponseToken: chargeToken,
      redirectFn: flowAPI.controllerConfig.wixCodeApi.location.to,
      newApi: true,
    })

    const location = await flowAPI.controllerConfig.platformApiProvider.getPlatformApi<ILocation>('location')

    switch (confirmPaymentResponse.clientStatus) {
      case 'Approved':
        location.to(orderPageUrls.success)
        break
      case 'Pending':
      case 'InProcess':
        location.to(orderPageUrls.pending)
        break
      case 'Failed':
      case 'Unknown':
      default:
        location.to(orderPageUrls.error)
        break
    }

    return confirmPaymentResponse
  },
)

export const getOrder = createAsyncAction<
  {order: wix.events.ticketing.Order; calendarLinks: any; dates: any},
  {eventId: string; orderNumber: string; token?: string}
>('GET_ORDER', async ({eventId, orderNumber, token = ''}, {extra: {serverApi}, dispatch, getState}) => {
  const state = getState()
  try {
    const response = await serverApi.getOrder(eventId, orderNumber, token)

    if ((isOrderSuccessRoute(state) || isOrderPendingRoute(state)) && isPreparingOrder(response.order)) {
      dispatch(pollOrder({eventId, orderNumber, token}))
    }

    return response
  } catch (error) {
    const responseStatus = error.response?.status

    if (responseStatus === 404) {
      dispatch(navigateToNotFound())
    } else if (responseStatus === 400) {
      dispatch(navigateToLinkExpired())
    } else {
      dispatch(pollOrder({eventId, orderNumber, token}))
    }

    throw new Error('Unhandled case, starting to poll order')
  }
})

export const pollOrder = createAsyncAction<void, {eventId: string; orderNumber: string; token?: string}>(
  'POLL_ORDER',
  async ({eventId, orderNumber, token = ''}, {extra: {serverApi, flowAPI}, dispatch}) => {
    if (!flowAPI.environment.isSSR) {
      await poll({
        callback: async () => {
          const {order} = await serverApi.getOrder(eventId, orderNumber, token)
          return !isPreparingOrder(order)
        },
        intervalMs: 1500,
        totalMs: 15000,
      })
    }

    dispatch(getOrder({eventId, orderNumber, token})).unwrap()
  },
)

export const getOrderTicketsDownloadUrl = createAsyncAction<
  {loadingTicketsUrl: boolean; downloadTicketsUrl?: string},
  number
>('GET_ORDER_TICKETS_DOWNLOAD_URL', async (retryLimit, {dispatch, getState, extra: {serverApi, flowAPI}}) => {
  const {token} = getNavigationQuery(getState())

  try {
    const response = await serverApi.getPapyrusTicketsDownloadUrl({token})
    if (response.ready && response.downloadUrl) {
      flowAPI.fedops.interactionEnded(Interaction.LoadingTickets)
      return {loadingTicketsUrl: !response.ready, downloadTicketsUrl: response.downloadUrl}
    }
    if (retryLimit > 0) {
      await waitALittle()
      dispatch(getOrderTicketsDownloadUrl(--retryLimit))
    } else {
      throw new Error(`Automatic retry limit exceeded, please retry by clicking download`)
    }
    return {loadingTicketsUrl: !response?.ready, downloadTicketsUrl: response?.downloadUrl}
  } catch (e) {
    console.error({e})
    flowAPI.reportError(e)
    if (retryLimit > 0) {
      await waitALittle()
      dispatch(getOrderTicketsDownloadUrl(--retryLimit))
    } else {
      return {loadingTicketsUrl: false}
    }
  }
})
