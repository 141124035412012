import {Store, bindActionCreators} from 'redux'
import {setFormFactor} from '../../../../commons/actions/environment'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {setPlaceDonationError, setSeatingError} from '../reducers/seating/errors'
import {resetFilters, selectLocation, selectPrice} from '../reducers/seating/filters'
import {
  selectPlace,
  setShowAccessibilityMode,
  setShowMobileBasket,
  setShowPlaceDescription,
  setShowPricingOptionsPlace,
  unselectPlace,
} from '../reducers/seating/mode'
import {updatePlace} from '../reducers/seating/places'
import {Actions} from '../types'
import {addToCalendarClicked, addToCalendarOptionClicked} from './calendar'
import {
  checkout,
  clearCheckout,
  editStep,
  getDiscount,
  handleNextStep,
  nextFormClicked,
  setExpandedTicketIndex,
  setTicketDetails,
  setUseBuyerDetails,
  setValidPaymentAdded,
  submitCheckoutStep,
} from './checkout'
import {updateStyleParams} from './component'
import {applyCoupon, removeCoupon, resetCouponCode} from './coupon'
import {getMembers, shareEvent} from './event'
import {applyGiftCard, removeGiftCard, resetGiftCardCode} from './gift-card'
import {joinGroup} from './groups'
import {clearInvoice} from './invoice'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from './loaded'
import {ensureLoginForMembersOnly, openMembersModal, promptLogin} from './members'
import {
  closeNativeModal,
  openCantCompletePaymentModal,
  openCheckoutUnavailable,
  openConfirmRsvp,
  openHeadlessErrorModal,
  openRecurringEventsModal,
  openSeatingPlan,
  openTicketsDetailsModal,
  openTicketsDownloadModal,
  openTimeExpiredModal,
} from './modals'
import {
  navigate,
  navigateTo,
  navigateToChangeRsvp,
  navigateToDetails,
  navigateToForm,
  navigateToHomePage,
  navigateToMainPage,
  navigateToOrder,
  navigateToTicketsPicker,
  navigateViaEditor,
  scrollToElement,
} from './navigation'
import {downloadTickets} from './order-success'
import {applyPlan, getPlanList, openMembershipPicker} from './paid-plans'
import {paymentMethodSelected, placeOrderButtonClicked} from './payment'
import {expressCheckout, getOrderTicketsDownloadUrl, placeOrder, updateOrder, updatePaymentMethod} from './placed-order'
import {openPolicyModal, toggleAgreePolicies} from './policies'
import {registrationButtonClicked} from './registration'
import {cancelReservation, clearReservationError, reserveTickets} from './reservation'
import {
  changeMemberRsvp,
  changeResponse,
  deleteRsvp,
  editRsvpStep,
  handleNextRsvpStep,
  handleRSVP,
  resetRsvpError,
  sendRsvp,
  submitRsvpStep,
  updateRsvp,
  updateRsvpStatus,
} from './rsvp'
import {navigateToSchedulePage} from './schedule'
import {seatingMapButtonClick} from './seating/bi'
import {seatingCheckout} from './seating/checkout'
import {
  addPlaceDonation,
  clickPlace,
  resetAreaCounterValue,
  selectPlacePricingOption,
  setAreaCounterValue,
  setPlaceQuantity,
} from './seating/place'
import {changeTicketDonation, selectTicket, setDonationError} from './selected-tickets'
import {updateSettings} from './settings'
import {getStaticMapUrl} from './static-map'
import {collapseDescription, expandDescription} from './tickets-picker'

export const getExportedActions = (store: Store): Actions => {
  const dispatchActions = {
    navigateToForm,
    navigateToMainPage,
    navigateToHomePage,
    detailsPageLoaded,
    formPageLoaded,
    thankYouPageLoaded,
    ticketedThankYouPageLoaded,
    changeMemberRsvp,
    registrationButtonClicked,
    updateStyleParams,
    resetCouponCode,
    resetGiftCardCode,
    removeCoupon,
    removeGiftCard,
    resetRsvpError,
    sendRsvp,
    updateRsvp,
    reserveTickets,
    selectTicket,
    ensureLoginForMembersOnly,
    expandDescription,
    collapseDescription,
    getMembers,
    getPlanList,
    openMembershipPicker,
    promptLogin,
    navigateToTicketsPicker,
    openCheckoutUnavailable,
    updateOrder,
    placeOrder,
    paymentMethodSelected,
    placeOrderButtonClicked,
    navigateToDetails,
    nextFormClicked,
    openHeadlessErrorModal,
    openTicketsDetailsModal,
    openTicketsDownloadModal,
    cancelReservation,
    applyCoupon,
    applyGiftCard,
    addToCalendarClicked,
    addToCalendarOptionClicked,
    applyPlan,
    handleRSVP,
    checkout,
    submitCheckoutStep,
    editStep,
    openTimeExpiredModal,
    openCantCompletePaymentModal,
    setValidPaymentAdded,
    navigate,
    navigateViaEditor,
    setUseBuyerDetails,
    setTicketDetails,
    clearInvoice,
    navigateToOrder,
    navigateToChangeRsvp,
    setExpandedTicketIndex,
    handleNextStep,
    openMembersModal,
    updateSettings,
    clearCheckout,
    getDiscount,
    downloadTickets,
    shareEvent,
    toggleAgreePolicies,
    openPolicyModal,
    submitRsvpStep,
    handleNextRsvpStep,
    editRsvpStep,
    changeResponse,
    setFormFactor,
    closeNativeModal,
    navigateToSchedulePage,
    openRecurringEventsModal,
    joinGroup,
    openSeatingPlan,
    openConfirmRsvp,
    setActiveElement,
    setPlaceQuantity,
    updatePlace,
    selectPrice,
    selectLocation,
    setShowAccessibilityMode,
    selectPlace,
    unselectPlace,
    setSeatingError,
    resetFilters,
    seatingMapButtonClick,
    clearReservationError,
    changeTicketDonation,
    setDonationError,
    addPlaceDonation,
    setPlaceDonationError,
    setShowPricingOptionsPlace,
    setShowMobileBasket,
    setShowPlaceDescription,
    clickPlace,
    seatingCheckout,
    selectPlacePricingOption,
    updateRsvpStatus,
    deleteRsvp,
    getOrderTicketsDownloadUrl,
    getStaticMapUrl,
    navigateTo,
    expressCheckout,
    scrollToElement,
    updatePaymentMethod,
    setAreaCounterValue,
    resetAreaCounterValue,
  }

  return {
    ...bindActionCreators(dispatchActions, store.dispatch),
  }
}
