import {getEventId} from '@wix/wix-events-commons-statics'
import {getEvent} from '../selectors/event'
import {getReservationId} from '../selectors/reservation'
import {GetState} from '../types'
import {getDiscount} from './checkout'

export const SUBMIT_COUPON = 'SUBMIT_COUPON'
export const RESET_COUPON_CODE = 'RESET_COUPON_CODE'
export const COUPON_APPLIED = 'COUPON_APPLIED'

export const removeCoupon = () => (dispatch: Function, getState: GetState) => {
  const state = getState()

  dispatch(resetCouponCode())
  dispatch(getDiscount({eventId: getEventId(getEvent(state)), reservationId: getReservationId(state)}))
}

export const resetCouponCode = () => ({type: RESET_COUPON_CODE})

export const submitCoupon = (couponCode: string) => ({
  type: SUBMIT_COUPON,
  payload: couponCode,
})

export const couponApplied = () => ({
  type: COUPON_APPLIED,
})

export const applyCoupon = (eventId: string, reservationId: string, couponCode: string) => (dispatch: Function) => {
  dispatch(submitCoupon(couponCode))
  return dispatch(getDiscount({eventId, reservationId}))
}
